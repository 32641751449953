<template>
  <div>
    <v-card-title class="text-wrap text-uppercase text-h6 text-center text-md-left" style="word-break: break-word">
      {{ title }}
    </v-card-title>
    <v-divider />
    <apexchart class="ma-3" type="line" height="250" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import _sortBy from "lodash/sortBy";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
export default {
  name: "GradePerformanceChart",
  props: {
    exams: Array,
    grade_reference: Number,
    title: String,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {};
  },

  computed: {
    chartOptions() {
      return {
        stroke: {
          curve: "smooth",
        },
        annotations: this.annotations,
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        // title: {
        //   text: this.$t("academic_performance"),
        //   align: "left",
        //   style: {
        //     fontSize: "1.25 rem",
        //     fontWeight: 500,
        //     fontFamily: '"Roboto", sans-serif',
        //   },
        // },
        markers: {
          hover: {
            sizeOffset: 2,
          },
          size: 6,
        },
        xaxis: {
          labels: {
            show: false,
            formatter: function (value) {
              return moment(value).format("l");
            },
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          min: 18,
          max: 30,
          tickAmount: 4,
        },
      };
    },

    annotations() {
      if (isNaN(this.grade_reference)) return {};
      return {
        yaxis: [
          {
            y: this.grade_reference,
            borderColor: this.$vuetify.theme.themes.light.danger,
            label: {
              borderColor: this.$vuetify.theme.themes.light.danger,
              style: {
                color: this.$vuetify.theme.themes.light.danger,
              },
              text: this.$t("gradeReference.grade_reference"),
              position: "left",
              textAnchor: "start",
            },
          },
        ],
      };
    },

    series() {
      return [
        // {
        //   data: _sortBy(this.progression, "date").map(({ cfu, date }) => ({ y: cfu, x: date })),
        // },
        {
          data: this.progression.map(({ arithmetic_average, date }) => ({
            y: arithmetic_average,
            x: date,
          })),
          name: this.$t("careerYear.arithmetic_average"),
          color: this.$vuetify.theme.themes.light.primary,
        },
        {
          data: this.progression.map(({ weighted_average, date }) => ({
            y: weighted_average,
            x: date,
          })),
          name: this.$t("careerYear.weighted_average"),
          color: this.$vuetify.theme.themes.light.accent,
        },
      ];
    },

    sortedExams() {
      if (this.exams.length == 0) return [];
      return _sortBy(this.exams, "date");
    },

    progression() {
      let exams = this.sortedExams.filter((exam) => exam.grade != "ido" && exam.grade != "app");

      if (exams.length == 0) return [];

      let progression = [];

      progression.push({
        cfu: parseInt(exams[0].cfu),
        arithmetic_average: parseFloat(exams[0].grade),
        weighted_average: parseFloat(exams[0].grade),
        date: exams[0].date,
      });

      for (let i = 0; i < exams.length - 1; i++) {
        const previous = progression[progression.length - 1];

        const aggregate = this.aggregateExam(previous, exams[i + 1], i + 1);

        if (exams[i + 1].date == previous.date) progression[progression.length - 1] = aggregate;
        else progression.push(aggregate);
      }
      return progression;
    },
  },
  methods: {
    aggregateExam(previous, exam, count) {
      const cfu = previous.cfu + parseInt(exam.cfu);
      const grade = exam.grade == "30L" ? 30.0 : parseFloat(exam.grade);

      return {
        cfu,
        arithmetic_average: (previous.arithmetic_average * count + grade) / (count + 1),
        weighted_average: (previous.weighted_average * previous.cfu + grade * parseInt(exam.cfu)) / cfu,
        date: exam.date,
      };
    },
  },
};
</script>
