<template>
  <base-form
    model="career"
    @save="career_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.career.loading"
    :currentValue="career"
    :defaultValue="{ type, ...baseItem }"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";

const CAREER_FRAGMENT = gql`
  fragment careerForm on Career {
    id
    name
    badge_number
    structure {
      id
      name
    }
    studyClass {
      id
      name
      code
      studyDuration {
        id
        name
      }
      classArea {
        id
        name
      }
    }
  }
`;

export default {
  components: { BaseForm },
  name: "CareerForm",
  props: {
    career_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  computed: {
    type() {
      if (!this.career_id) return "F";
      else return this.career?.studyClass ? "F" : "S";
    },
  },

  watch: {
    career_id(val) {
      if (val == null) this.career = {};
    },
  },

  apollo: {
    career: {
      query: gql`
        query Career($id: ID!) {
          career(id: $id) {
            ...career
          }
        }
        ${CAREER_FRAGMENT}
      `,
      variables() {
        return { id: this.career_id };
      },
      skip() {
        return this.career_id == null;
      },
    },
  },
  data() {
    return {
      newValue: {},
      career: {},
      saving: false,
      fields: [
        {
          name: "type",
          type: "autocomplete",
          items: ["F", "S"].map((value) => ({
            text: this.$t(`options.career.type.${value}`),
            value,
          })),
          validation: {
            required,
          },
        },
        {
          name: "structure",
          type: "query",
          path: "structures",
          query: gql`
            query Structures {
              structures {
                id
                name
              }
            }
          `,
          label: this.$t("structure.name"),
          validation: {
            required,
          },
        },
        {
          name: "studyClass",
          type: "query",
          path: "studyClasses",
          query: gql`
            query StudyClasses {
              studyClasses {
                id
                name
              }
            }
          `,
          label: this.$t("studyClass.name"),
          bind: {
            clearable: true,
          },
          validation: {
            required,
          },
          isVisible: (currentValue) => currentValue.type == "F",
        },
        {
          name: "name",
          validation: {
            required,
          },
          isVisible: (currentValue) => currentValue.type == "S",
        },
        {
          name: "badge_number",
          validation: {
            required,
          },
        },
      ],
    };
  },
  methods: {
    update() {
      this.saving = true;

      let { type, ...input } = this.newValue;

      if (type != this.type) {
        if (type == "F") {
          input.name = null;
        } else {
          input.study_class_id = null;
        }
      }

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Career", fragment: CAREER_FRAGMENT, fragment_name: "careerForm" }),
          variables: {
            id: this.career_id,
            input,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      let { type, ...input } = this.newValue;

      if (type != this.type) {
        if (type == "F") {
          delete input.name;
        } else {
          delete input.study_class_id;
        }
      }

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "Career", fragment: CAREER_FRAGMENT, fragment_name: "careerForm" }),
          variables: {
            input,
          },
        })
        .then(({ data: { createCareer } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createCareer);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
