<template>
  <base-form
    model="career_year"
    @save="career_year_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.careerYear.loading"
    :currentValue="careerYear"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import { ucFirst } from "../../apollo/helpers";

const CAREER_YEAR_FORM_FRAGMENT = gql`
  fragment careerYearForm on CareerYear {
    id
    classYear {
      id
      name
    }
    studentYear {
      id
      year {
        id
        name
      }
    }
  }
`;

export default {
  components: { BaseForm },
  name: "careerYearForm",
  props: {
    career_year_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    career_year_id(val) {
      if (val == null) this.careerYear = {};
    },
  },

  apollo: {
    careerYear: {
      query: gql`
        query CareerYear($id: ID!) {
          careerYear(id: $id) {
            ...careerYearForm
          }
        }
        ${CAREER_YEAR_FORM_FRAGMENT}
      `,
      variables() {
        return { id: this.career_year_id };
      },
      skip() {
        return this.career_year_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      careerYear: {},
      saving: false,
      fields: [
        {
          name: "classYear",
          type: "query",
          path: "career.studyClass.studyDuration.classYears",
          label: ucFirst(this.$t("classYear.name")),
          query: gql`
            query CareerClassYears($id: ID!) {
              career(id: $id) {
                id
                studyClass {
                  id
                  studyDuration {
                    id
                    classYears {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          variables: () => {
            return { id: this.baseItem.career.id };
          },
          validation: {
            required,
          },
        },
        {
          name: "studentYear",
          type: "query",
          path: "career.student.studentYears",
          label: ucFirst(this.$t("studentYear.name")),
          text: "year.name",
          query: gql`
            query CareerStudentYears($id: ID!) {
              career(id: $id) {
                id
                student {
                  id
                  studentYears {
                    id
                    year {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,

          variables: () => {
            return { id: this.baseItem.career.id };
          },
          bind: {
            clearable: true,
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "CareerYear", fragment: CAREER_YEAR_FORM_FRAGMENT, fragment_name: "careerYearForm" }),
          variables: {
            id: this.career_year_id,
            input: this.newValue,
          },
        })
        .then(({ data: { updateCareerYear } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save", updateCareerYear);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "CareerYear", fragment: CAREER_YEAR_FORM_FRAGMENT, fragment_name: "careerYearForm" }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createCareerYear } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createCareerYear);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
