<template>
  <v-row class="justify-center">
    <v-col cols="12">
      <primary-career-card :studentYear="studentYear" @save="refetch" />
      <v-card class="overflow-hidden" rounded="lg">
        <v-card-title>
          <career-year-select v-model="selectedCareerYear" :student_year_id="student_year_id" />
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="careerYear">
      <v-row>
        <v-col cols="12" xl="6">
          <v-card class="overflow-hidden" rounded="lg">
            <exams-table
              :title="$t('careerYear.passedExams')"
              :tableProps="{ viewOnly: !careerYear.canCreateExams }"
              :exams="careerYear.passedExams"
              :baseItem="{ careerYear, done: true }"
            />
          </v-card>
        </v-col>
        <v-col cols="12" xl="6">
          <v-card class="overflow-hidden" rounded="lg">
            <exams-table
              :title="$t('careerYear.plannedExams')"
              :tableProps="{ viewOnly: !careerYear.canCreateExams }"
              :exams="careerYear.plannedExams"
              :baseItem="{ careerYear, done: false }"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="overflow-hidden" rounded="lg">
            <grade-performance-chart
              :title="$t('career_year_performance')"
              :exams="careerYear.passedExams.filter((exam) => !exam.extra_plan)"
              :grade_reference="grade_reference"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import { updateMutationBuilder } from "../../graphql/mutations";
import { EXAM_FRAGMENT } from "../../graphql/fragments";
import PrimaryCareerCard, { PRIMARY_CAREER_YEAR_FRAGMENT } from "./PrimaryCareerCard.vue";
import CareerYearSelect from "../inputs/CareerYearSelect.vue";
import GradePerformanceChart from "./statistics/GradePerformanceChart.vue";
import ExamsTable from "../tables/ExamsTable.vue";
import moment from "moment";

const CAREER_YEAR_FRAGMENT = gql`
  fragment careerYear on CareerYear {
    id
    canCreateExams
    passedExams: exams(done: true) {
      ...exam
    }
    plannedExams: exams(done: false) {
      ...exam
    }
    career {
      id
      studyClass {
        id
        name
      }
    }
  }
  ${EXAM_FRAGMENT}
`;

export const EXAM_WITH_AGGREGATES_FRAGMENT = gql`
  fragment examWithAggregates on Exam {
    ...exam
    careerYear {
      id
      cfu
      partial_cfu
      arithmetic_average
      weighted_average
      studentYear {
        id
        academic_profile_verified_at
        academicProfileVerifiedBy {
          id
          complete_name
        }
      }
      career {
        id
        arithmetic_average
        weighted_average
      }
    }
  }
  ${EXAM_FRAGMENT}
`;

const STUDENT_PRIMARY_CAREER_FRAGMENT = gql`
  fragment studentPrimaryCareerYear on StudentYear {
    id
    canUpdate
    ...primaryCareerYear
  }
  ${PRIMARY_CAREER_YEAR_FRAGMENT}
`;

export default {
  name: "StudentYearAcademic",
  components: {
    PrimaryCareerCard,
    CareerYearSelect,
    GradePerformanceChart,
    ExamsTable,
  },

  props: {
    student_year_id: Number,
  },

  watch: {
    student_year_id() {
      this.careerYear = null;
      this.selectedCareerYear = null;
    },
  },

  data() {
    return {
      selectedCareerYear: null,
      careerYear: null,
      studentYear: {},
      gradient: ["#1feaea", "#ffd200", "#f72047"],
      moment,
    };
  },

  computed: {
    grade_reference() {
      return parseFloat(this.studentYear.primaryCareerYear?.gradeReference?.grade_reference);
    },
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYearAcademic($id: ID!) {
          studentYear(id: $id) {
            ...studentPrimaryCareerYear
          }
        }
        ${STUDENT_PRIMARY_CAREER_FRAGMENT}
      `,
      variables() {
        return { id: this.student_year_id };
      },
      result() {
        this.selectedCareerYear = this.studentYear.primaryCareerYear;
      },
    },

    careerYear: {
      query: gql`
        query CareerYear($id: ID!) {
          careerYear(id: $id) {
            ...careerYear
          }
        }
        ${CAREER_YEAR_FRAGMENT}
      `,
      variables() {
        return { id: this.selectedCareerYear.id };
      },
      skip() {
        return !this.selectedCareerYear;
      },
    },
  },

  methods: {
    save({ id, ...input }) {
      this.$apollo.mutate({
        mutation: updateMutationBuilder({
          model: "StudentYear",
          fragment: STUDENT_PRIMARY_CAREER_FRAGMENT,
          fragment_name: "studentPrimaryCareerYear",
        }),
        variables: {
          id,
          input,
        },
      });
    },
    refetch() {
      this.$apollo.queries.studentYear.refetch();
    },
  },
};
</script>

<style></style>
