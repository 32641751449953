<template>
  <edit-card
    :info="studentAcademicInfo"
    class="mb-4"
    model="studentacademic"
    :item="studentYear"
    :title="$tc('models.studentacademic', 1)"
    @save="$emit('save')"
  >
    <template v-slot:form="{ close }">
      <student-primary-career-form
        :primary-career-year-fragment="PRIMARY_CAREER_YEAR_FRAGMENT"
        :student_year_id="studentYear.id"
        @cancel="close"
        @save="close"
      />
    </template>

    <template #action>
      <VerifyButton
        @verify="verifyAcademicProfile"
        :verifing="verifing"
        :verified-at="studentYear.academic_profile_verified_at"
        :verified-by="studentYear.academicProfileVerifiedBy"
        :can-verify="studentYear.canVerifyAcademicProfile"
      ></VerifyButton>
    </template>
  </edit-card>
</template>

<script>
import EditCard from "../base/EditCard.vue";
import StudentPrimaryCareerForm from "../forms/StudentPrimaryCareerForm.vue";
import gql from "graphql-tag";
import VerifyButton from "./VerifyButton.vue";

export const PRIMARY_CAREER_YEAR_FRAGMENT = gql`
  fragment primaryCareerYear on StudentYear {
    id
    canVerifyAcademicProfile
    academic_profile_verified_at
    academicProfileVerifiedBy {
      id
      complete_name
    }
    primaryCareerYear {
      id
      arithmetic_average
      weighted_average
      partial_cfu
      cfu
      gradeReference {
        id
        grade_reference
      }
      classYear {
        id
        name
      }
      career {
        id
        arithmetic_average
        weighted_average
        structure {
          id
          name
        }
        studyClass {
          id
          name
          code
          classArea {
            id
            name
          }
        }
      }
    }
  }
`;

export default {
  components: { EditCard, StudentPrimaryCareerForm, VerifyButton },
  name: "PrimaryCareerCard",
  props: {
    studentYear: Object,
  },

  data() {
    return {
      PRIMARY_CAREER_YEAR_FRAGMENT,
      verifing: false,
    };
  },

  computed: {
    studentAcademicInfo() {
      const fields = [
        {
          field: "primaryCareerYear.career.structure.name",
          label: this.$tc("structure.name"),
          cols: 12,
        },
        {
          field: "primaryCareerYear.career.studyClass.code",
          label: this.$tc("studyClass.code"),
          cols: 12,
        },
        {
          field: "primaryCareerYear.career.studyClass.name",
          label: this.$tc("fields.primaryCareerYear"),
        },
        {
          field: "primaryCareerYear.classYear.name",
          label: this.$tc("careerYear.name"),
        },
        {
          field: "primaryCareerYear.career.studyClass.classArea.name",
          label: this.$tc("classArea.name"),
        },
        {
          field: "primaryCareerYear.gradeReference.grade_reference",
          label: this.$tc("gradeReference.grade_reference"),
          type: "float",
        },
        {
          field: "primaryCareerYear.arithmetic_average",
          label: this.$tc("careerYear.arithmetic_average"),
          type: "float",
        },
        {
          field: "primaryCareerYear.weighted_average",
          label: this.$tc("careerYear.weighted_average"),
          type: "float",
        },
        {
          field: "primaryCareerYear.partial_cfu",
          label: this.$tc("careerYear.partial_cfu"),
        },
        {
          field: "primaryCareerYear.career.arithmetic_average",
          label: this.$t("career.arithmetic_average"),
          type: "float",
        },
        {
          field: "primaryCareerYear.career.weighted_average",
          label: this.$t("career.weighted_average"),
          type: "float",
        },
        {
          field: "primaryCareerYear.cfu",
          label: this.$tc("careerYear.cfu"),
        },
      ];

      return fields;
    },
  },

  methods: {
    verifyAcademicProfile() {
      this.verifing = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation VerifyAcademicProfile($id: ID!) {
              verifyAcademicProfile(id: $id) {
                id
                academic_profile_verified_at
                academicProfileVerifiedBy {
                  id
                  complete_name
                }
              }
            }
          `,
          variables: { id: this.studentYear.id },
        })
        .finally(() => (this.verifing = false));
    },
  },
};
</script>
