<template>
  <v-select
    :items="careerYears"
    item-text="career.studyClass.name"
    item-value="id"
    :value="value"
    @input="(value) => $emit('input', value)"
    return-object
    :loading="loading"
    :label="ucFirst($tc('models.career', 1))"
  ></v-select>
</template>

<script>
import gql from "graphql-tag";
import { ucFirst } from "../../apollo/helpers";
export default {
  name: "CareerYearSelect",
  data() {
    return {
      careerYears: [],
      ucFirst,
    };
  },
  props: ["value", "student_year_id"],
  apollo: {
    careerYears: {
      query: gql`
        query studentYearCareerYears($id: ID!) {
          studentYear(id: $id) {
            id
            careerYears {
              id
              career {
                id
                studyClass {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.student_year_id };
      },
      update: (data) => data.studentYear.careerYears,
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.careerYears.loading;
    },
  },
};
</script>

<style>
</style>