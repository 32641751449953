<template>
  <base-form
    model="studentYear"
    @save="student_year_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.studentYear.loading"
    :currentValue="studentYear"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form"
    :title="$t('studentYear.primaryCareerYear')"
  />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";

export default {
  components: { BaseForm },
  name: "StudentPrimaryCareerForm",
  props: {
    student_year_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
    primaryCareerYearFragment: { required: true },
  },

  watch: {
    student_year_id(val) {
      if (val == null) this.studentYear = {};
    },
  },

  apollo: {
    studentYear: {
      query: gql`
        query StudentYear($id: ID!) {
          studentYear(id: $id) {
            id
            primaryCareerYear {
              id
              career {
                id
                studyClass {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return { id: this.student_year_id };
      },
      skip() {
        return this.student_year_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      studentYear: {},
      saving: false,
      fields: [
        {
          name: "primaryCareerYear",
          type: "query",
          path: "studentYear.careerYears",
          query: gql`
            query studentYear($id: ID!) {
              studentYear(id: $id) {
                id
                careerYears {
                  id
                  career {
                    id
                    studyClass {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          variables: () => {
            return {
              id: this.student_year_id,
            };
          },
          text: "career.studyClass.name",
          bind: {
            clearable: true,
          },
        },
      ],
    };
  },

  computed: {
    studentPrimaryCareerYearFormFragment() {
      return gql`
        fragment studentPrimaryCareerYearForm on StudentYear {
          id
          ...primaryCareerYear
        }
        ${this.primaryCareerYearFragment}
      `;
    },
  },

  methods: {
    update() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({
            model: "StudentYear",
            fragment: this.studentPrimaryCareerYearFormFragment,
            fragment_name: "studentPrimaryCareerYearForm",
          }),
          variables: {
            id: this.student_year_id,
            input: this.newValue,
          },
        })
        .then(() => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save");
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({
            model: "StudentYear",
            fragment: this.studentPrimaryCareerYearFormFragment,
            fragment_name: "studentPrimaryCareerYearForm",
          }),
          variables: {
            input: { ...this.newValue },
          },
        })
        .then(({ data: { createStudentYear } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createStudentYear);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
