<template>
  <base-form
    model="exam"
    @save="exam_id && !duplicating ? update() : create()"
    :fields="fields"
    :loading="$apollo.queries.exam.loading"
    :currentValue="exam"
    :defaultValue="baseItem"
    @input="(value) => (newValue = value)"
    :saving="saving"
    :duplicating="duplicating"
    @cancel="$emit('cancel')"
    ref="form" />
</template>

<script>
import gql from "graphql-tag";
import BaseForm from "./BaseForm.vue";
import { createMutationBuilder, updateMutationBuilder } from "../../graphql/mutations";
import { required } from "vuelidate/lib/validators";
import _range from "lodash/range";
import { EXAM_WITH_AGGREGATES_FRAGMENT } from "../profile/StudentYearAcademic.vue";

const EXAM_FRAGMENT = gql`
  fragment examForm on Exam {
    id
    name
    done
    extra_plan
    cfu
    grade
    date
    careerYear {
      id
      classYear {
        id
        name
      }
    }
    classYear {
      id
      name
    }
  }
`;

export default {
  components: { BaseForm },
  name: "ExamForm",
  props: {
    exam_id: {},
    baseItem: { default: () => ({}) },
    duplicating: Boolean,
  },

  watch: {
    exam_id(val) {
      if (val == null) this.exam = {};
    },
  },

  apollo: {
    exam: {
      query: gql`
        query Exam($id: ID!) {
          exam(id: $id) {
            ...examForm
          }
        }
        ${EXAM_FRAGMENT}
      `,
      variables() {
        return { id: this.exam_id };
      },
      skip() {
        return this.exam_id == null;
      },
    },
  },

  data() {
    return {
      newValue: {},
      exam: {},
      saving: false,
      fields: [
        {
          name: "name",
          validation: {
            required,
          },
        },
        {
          name: "cfu",
          type: "number",
          validation: {
            required,
          },
        },
        {
          name: "done",
          type: "checkbox",
          isVisible: () => !("career" in this.baseItem),
        },
        {
          name: "extra_plan",
          type: "checkbox",
        },
        {
          name: "date",
          type: "date",
          validation: {
            required,
          },
          isVisible: ({ done }) => done,
        },
        {
          name: "date",
          type: "date",
          isVisible: ({ done }) => !done,
        },
        {
          name: "grade",
          type: "autocomplete",
          items: [
            { value: "app", text: "Approvato" },
            { value: "ido", text: "Idoneità" },
            { value: "30L", text: "30 e lode" },
            ..._range(18, 31)
              .map((num) => num.toString())
              .reverse(),
          ],
          no_translation: true,
          validation: {
            required,
          },
          isVisible: ({ done }) => done,
        },
        {
          name: "classYear",
          path: "careerYear.career.studyClass.studyDuration.classYears",
          type: "query",
          query: gql`
            query ClassYears($career_year_id: ID!) {
              careerYear(id: $career_year_id) {
                id
                career {
                  id
                  studyClass {
                    id
                    studyDuration {
                      id
                      classYears {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: () => ({ career_year_id: this.baseItem.careerYear.id }),
          isVisible: () => "careerYear" in this.baseItem,
          validation: {
            required,
          },
        },
        {
          name: "classYear",
          path: "career.studyClass.studyDuration.classYears",
          type: "query",
          query: gql`
            query CareerClassYears($career_id: ID!) {
              career(id: $career_id) {
                id
                studyClass {
                  id
                  studyDuration {
                    id
                    classYears {
                      id
                      name
                    }
                  }
                }
              }
            }
          `,
          variables: () => ({ career_id: this.baseItem.career.id }),
          isVisible: () => "career" in this.baseItem,
          validation: {
            required,
          },
        },
        {
          label: this.$t("careerYear.name"),
          name: "careerYear",
          path: "career.careerYears",
          text: "classYear.name",
          type: "query",
          query: gql`
            query CareerCareerYears($career_id: ID!) {
              career(id: $career_id) {
                id
                careerYears {
                  id
                  classYear {
                    id
                    name
                  }
                }
              }
            }
          `,
          variables: () => ({ career_id: this.baseItem.career.id }),
          isVisible: () => "career" in this.baseItem,
          validation: {
            required,
          },
        },
      ],
    };
  },

  methods: {
    update() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { career_id, ...input } = this.newValue;

      this.$apollo
        .mutate({
          mutation: updateMutationBuilder({ model: "Exam", fragment: EXAM_WITH_AGGREGATES_FRAGMENT, fragment_name: "examWithAggregates" }),
          variables: {
            id: this.exam_id,
            input,
          },
        })
        .then(({ data: { updateExam } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("save", updateExam);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },

    create() {
      this.saving = true;

      // eslint-disable-next-line no-unused-vars
      const { career_id, ...input } = this.newValue;

      this.$apollo
        .mutate({
          mutation: createMutationBuilder({ model: "Exam", fragment: EXAM_WITH_AGGREGATES_FRAGMENT, fragment_name: "examWithAggregates" }),
          variables: {
            input,
          },
        })
        .then(({ data: { createExam } }) => {
          this.$refs.form.resetInfo();
          this.saving = false;
          this.$emit("created", createExam);
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
        });
    },
  },
};
</script>
