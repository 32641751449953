<template>
  <base-table
    :headers="headers"
    v-bind="tableProps"
    :title="title"
    model="exam"
    :items="exams"
    :tableProps="{ dense: true }"
    @deleteItem="deleteExam">
    <template v-slot:form="{ selected_id, close, duplicating }">
      <exam-form
        :exam_id="selected_id"
        :baseItem="baseItem"
        @cancel="close"
        :duplicating="duplicating"
        @save="(exam) => examUpdated(exam, close)"
        @created="(exam) => examCreated(exam, close)" />
    </template>
  </base-table>
</template>

<script>
import gql from "graphql-tag";
import { addToCacheFragmentArray, removeFromCacheFragmentArray } from "../../apollo/helpers";
import { deleteMutationBuilder } from "../../graphql/mutations";
import { EXAM_FRAGMENT } from "../../graphql/fragments";
import { EXAM_WITH_AGGREGATES_FRAGMENT } from "../profile/StudentYearAcademic.vue";
import BaseTable from "./BaseTable.vue";
import ExamForm from "../forms/ExamForm.vue";

export default {
  components: { BaseTable, ExamForm },
  name: "ExamsTable",
  props: {
    exams: Array,
    baseItem: { type: Object, default: () => ({}) },
    tableProps: { type: Object, default: () => ({}) },
    title: String,
  },
  data() {
    return {
      headers: [
        { value: "name", text: this.$t("exam.name") },
        { value: "cfu", text: this.$t("exam.cfu") },
        { value: "grade", text: this.$t("exam.grade") },
        { value: "careerYear.classYear.name", text: this.$t("careerYear.name") },
        { value: "classYear.name", text: this.$t("exam.referenceYear") },
        { value: "date", text: this.$t("exam.date"), type: "date" },
      ],
    };
  },
  methods: {
    fragment_info_builder(exam, done) {
      if (this.baseItem.careerYear)
        return {
          id: `CareerYear:${exam.careerYear.id}`,
          fragment: gql`fragment careerYearExams${done} on CareerYear {
                exams(done: ${done}) {
                  ...exam
                }
              }
              ${EXAM_FRAGMENT}`,
          fragmentName: `careerYearExams${done}`,
        };
      else
        return {
          id: `Career:${this.baseItem.career.id}`,
          fragment: gql`fragment careerExams${done} on Career {
                exams(done: ${done}) {
                  ...exam
                }
              }
              ${EXAM_FRAGMENT}`,
          fragmentName: `careerExams${done}`,
        };
    },

    deleteExam(exam) {
      this.$apollo.mutate({
        mutation: deleteMutationBuilder({ model: "Exam", fragment: EXAM_WITH_AGGREGATES_FRAGMENT, fragment_name: "examWithAggregates" }),
        variables: { id: exam.id },
        update: (store, { data: { deleteExam: exam } }) =>
          removeFromCacheFragmentArray(store, {
            fragment_info: this.fragment_info_builder(exam, exam.done),
            array_path: "exams",
            id: exam.id,
          }),
      });
    },

    examCreated(exam, close) {
      addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
        fragment_info: this.fragment_info_builder(exam, exam.done),
        array_path: "exams",
        newElement: exam,
        orderBy: { date: "asc" },
      });
      close();
    },

    examUpdated(exam, close) {
      if (exam.done != this.baseItem.done) {
        removeFromCacheFragmentArray(this.$apollo.provider.defaultClient, {
          fragment_info: this.fragment_info_builder(exam, !exam.done),
          array_path: "exams",
          id: exam.id,
        });
        addToCacheFragmentArray(this.$apollo.provider.defaultClient, {
          fragment_info: this.fragment_info_builder(exam, exam.done),
          array_path: "exams",
          newElement: exam,
          orderBy: { date: "asc" },
        });
      }
      close();
    },
  },
};
</script>
