<template>
  <div>
    <v-card-title class="text-wrap text-uppercase text-h6 text-center text-md-left" style="word-break: break-word">
      {{ $t("cfu_performance") }}
    </v-card-title>
    <v-divider />
    <apexchart class="ma-3" type="bar" height="250" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import gql from "graphql-tag";
import { ucFirst } from "../../../apollo/helpers";

export const CFU_CAREER_FRAGMENT = gql`
  fragment cfuCareerFragment on Career {
    careerYears {
      id
      cfu
      cfuReference {
        id
        cfu_reference
      }
      studentYear {
        id
        year {
          id
          name
        }
      }
    }
  }
`;

export default {
  name: "CfuPerformanceChart",
  props: {
    careerYears: Array,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: "CFU conseguiti",
          data: this.careerYears.map(({ cfu }) => cfu),
        },
        {
          name: ucFirst(this.$tc("models.cfuReference", 1)),
          data: this.careerYears.map((careerYear) => careerYear.cfuReference?.cfu_reference),
        },
      ],
    };
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.careerYears.map((careerYear) => careerYear.studentYear.year.name),
        },
        yaxis: {
          title: {
            text: "CFU",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " CFU";
            },
          },
        },
      };
    },
  },
};
</script>
