<template>
  <v-container>
    <v-row>
      <v-col md="4" cols="12">
        <edit-card :info="info" v-if="!loading" :item="career" model="career">
          <template v-slot:title><back-button :to="backRoute" :text="title" /></template>
          <template v-slot:form="{ close }">
            <career-form :career_id="career.id" @cancel="close" @save="close" />
          </template>
        </edit-card>
        <v-skeleton-loader type="card" v-else rounded="lg" />
      </v-col>
      <v-col v-if="!loading" md="8" cols="12">
        <v-card class="overflow-hidden" rounded="lg">
          <crud-table
            :title="$tc('models.career_year', 2)"
            model="career_year"
            :items="career.careerYears"
            :baseItem="{ career_id: this.$route.params.id }"
            @deleteItem="deleteCareerYear">
            <template v-slot:[`studentYear.year.name`]="{ item }">
              {{ item.studentYear ? item.studentYear.year.name : "Nessuno" }}
            </template>
            <template v-slot:form="{ selected_id, close, duplicating }">
              <career-year-form
                :career_year_id="selected_id"
                :baseItem="{ career }"
                @cancel="close"
                @save="close"
                :duplicating="duplicating"
                @created="(newElement) => careerYearCreated(newElement, close)" />
            </template>
          </crud-table>
        </v-card>

        <br />

        <v-card class="overflow-hidden" rounded="lg">
          <exams-table :title="$tc('career.passedExams')" :exams="career.exams" :baseItem="{ career, done: true }" />
        </v-card>

        <br />

        <v-card class="overflow-hidden" rounded="lg">
          <grade-performance-chart :title="$t('career_performance')" :exams="career.exams.filter((exam) => !exam.extra_plan)" />
        </v-card>

        <br />

        <v-card class="overflow-hidden" rounded="lg">
          <cfu-performance-chart :careerYears="career.careerYears.filter(({ studentYear }) => studentYear)" />
        </v-card>
      </v-col>
      <v-col v-else md="8" cols="12">
        <v-skeleton-loader type="card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CrudTable from "@/components/base/CrudTable.vue";
import ExamsTable from "@/components/tables/ExamsTable.vue";
import EditCard from "../components/base/EditCard.vue";
import BackButton from "../components/BackButton.vue";
import GradePerformanceChart from "../components/profile/statistics/GradePerformanceChart.vue";
import gql from "graphql-tag";
import fragments from "../graphql/fragments";
import CareerForm from "../components/forms/CareerForm.vue";
import CareerYearForm from "../components/forms/CareerYearForm.vue";
import { addToCacheArray, deleteModel } from "../apollo/helpers";
import CfuPerformanceChart, { CFU_CAREER_FRAGMENT } from "../components/profile/statistics/CfuPerformanceChart.vue";

const CAREER = gql`
  query Career($id: ID!) {
    career(id: $id) {
      id
      name
      canUpdate
      structure {
        id
        name
      }
      studyClass {
        id
        name
        studyDuration {
          id
          name
        }
        classArea {
          id
          name
        }
      }
      exams(done: true) {
        ...exam
      }
      student {
        id
        name
        user {
          id
        }
      }

      careerYears {
        ...career_year
      }

      ...cfuCareerFragment
    }
  }
  ${CFU_CAREER_FRAGMENT}
  ${fragments.exam}
  ${fragments.career_year}
`;

export default {
  components: {
    ExamsTable,
    EditCard,
    BackButton,
    GradePerformanceChart,
    CareerForm,
    CareerYearForm,
    CfuPerformanceChart,
    CrudTable,
  },
  data() {
    return {};
  },
  apollo: {
    career: {
      query: CAREER,
      variables() {
        return {
          id: this.$route.params.career_id,
        };
      },
      update(data) {
        let career = data.career;
        career.type = career?.studyClass ? "F" : "S";
        return career;
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.career.loading;
    },
    backRoute() {
      return { name: "UserProfile", params: this.$route.params };
    },

    info() {
      return [
        { field: "type", type: "autocomplete", cols: 12 },
        { field: "name", cols: 12 },
        { field: "studyClass.name", label: this.$t("studyClass.name"), cols: 12 },
        { field: "structure.name", label: this.$t("structure.name"), cols: 12 },
        { field: "studyClass.studyDuration.name", label: this.$t("fields.studyDuration"), cols: 12 },
      ];
    },

    title() {
      return this.career.studyClass?.name ?? this.career.name;
    },
  },

  methods: {
    examCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: CAREER,
          variables: {
            id: this.$route.params.career_id,
          },
        },
        queryField: "career.exams",
        newElement,
        unshift: true,
      });
      close();
    },

    careerYearCreated(newElement, close) {
      addToCacheArray(this.$apollo.provider.defaultClient, {
        query: {
          query: CAREER,
          variables: {
            id: this.$route.params.career_id,
          },
        },
        queryField: "career.careerYears",
        newElement,
        unshift: true,
      });
      close();
    },

    deleteCareerYear({ id }) {
      deleteModel(this.$apollo, {
        id,
        query: {
          query: CAREER,
          variables: {
            id: this.$route.params.career_id,
          },
        },
        queryField: "career.careerYears",
        model: "CareerYear",
      });
    },
  },
};
</script>

<style></style>
