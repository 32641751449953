<template>
  <v-tooltip v-if="verifiedAt" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-chip small color="success" v-bind="attrs" v-on="on"> {{ $t("verified") }} </v-chip>
    </template>
    <span>{{ moment(verifiedAt).format("l") }} - {{ verifiedBy.complete_name }}</span>
  </v-tooltip>
  <v-btn v-else-if="canVerify" small elevation="0" @click="$emit('verify')" color="warning" :loading="verifing">
    {{ $t("verify") }}
  </v-btn>
  <v-chip small class="mr-2" v-else color="warning">
    {{ $t("waiting_for_verification") }}
  </v-chip>
</template>

<script>
import moment from "moment";

export default {
  props: {
    verifiedBy: {
      type: Object,
    },
    verifiedAt: {
      type: String,
    },
    canVerify: {
      type: Boolean,
      default: false,
    },
    verifing: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    moment,
  },
};
</script>
